export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Windsurf Editor",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.youtube.com/embed/3xk2qG2QPdU?si=EVJZDAigCcZ87bMF",
    domain: "windsurfeditor.com",
    gaId: "G-11L70T8MD0",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
